import { useLocation } from "react-router-dom";


const GetToDisp = () => {

    const location = useLocation();
    const pathname = location.pathname;

    let page = pathname.replace('/dashboard/','') || 'Overview';

        if (page === '/dashboard') {
            page = 'overview';
        } else if (page === 'services' || page === 'services/international' && page !== 'services/payinvoice' && page !== 'services/utility' && page !== 'services/property') {
            page = 'money Transfer';
        } else if (page === 'services/payinvoice') {
            page = 'Pay Invoice';
        } else if (page === 'services/utility') {
            page = 'Utility Bills';
        } else if (page === 'services/property') {
            page = 'property management';
        }

        return page;
};

const capitalWord = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export { GetToDisp, capitalWord };
