import "./dashboard.styles.scss";
import { SideBar } from "components/dashboard/sidebar/sidebar";
import { useEffect } from "react";
import { faAngleDown, faArrowDownLong, faArrowRight, faArrowTrendUp, faCircleExclamation, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



export const Dashboard = ({ title }) => {
  useEffect(() => {
    document.title = title;
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  


  return ( 
    <div className="overview-container">

      <section className="theOverview">
        <SideBar />

        <div className="mainflex">

          <div className="welcome">
              <p className="greet"> Welcome Ifeoluwa 🖐 </p>
              <div>
                <p> <FontAwesomeIcon icon={faCircleExclamation} /> Please complete your verification process </p>
                <button>
                  <p> Withdraw funds <span> <FontAwesomeIcon icon={faArrowRight} /> </span> </p> 
                </button>
              </div>
          </div>

          <div className="stats">
            <div className="bal">
              <div>
                <p> Available Balance</p>
                <p>#00,00</p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                <span> 2.34% <span> </span> <FontAwesomeIcon icon={faArrowTrendUp} /> </span>
              </div>
            </div>
            <div className="bal">
              <div>
                <p> Withdrawals </p>
                <p> 01 </p>
              </div>
            </div>
            <div className="bal">
              <div>
                <p> Total points</p>
                <p> 1400 </p>
              </div>
            </div>
          </div>

          <div className="props">
            
            <div className="leftside">
              <h3> Recent Transactions </h3>
              <div className="details">
                <div className="search">
                  <button type="button"> All <FontAwesomeIcon icon={faAngleDown} /> </button>
                  <div>
                    <FontAwesomeIcon icon={faSearch} />
                    <input type="text" placeholder="Search transactions" />
                  </div>
                </div> <hr />

                <div className="title">
                  <p> Description </p>
                  <p> Date </p>
                  <p> Amount </p>
                </div> <hr style={{ margin: 'unset' }} />

                <div className="dets">
                  <div> 
                    <p> <FontAwesomeIcon icon={faArrowDownLong} style={{ color: 'green' }} /> </p>
                    <p> Received NGN 30,000 in wallet for intern - allowance for March 2023 from OACO Professional services </p>
                  </div>
                  <div className="middle">
                    <p> 12/06/2020 </p>
                    <span> 5:45 am </span>
                  </div>
                  <div>
                    <p> ₦1,000,000 </p>
                    <p> Successful </p>
                  </div>
                </div> <hr />

                <div className="dets">
                  <div> 
                    <p> <FontAwesomeIcon icon={faArrowDownLong} style={{ color: 'green' }} /> </p>
                    <p> Received NGN 30,000 in wallet for intern - allowance for March 2023 from OACO Professional services </p>
                  </div>
                  <div className="middle">
                    <p> 12/06/2020 </p>
                    <span> 5:45 am </span>
                  </div>
                  <div>
                    <p> ₦1,000,000 </p>
                    <p> Successful </p>
                  </div>
                </div> <hr />

                <div className="dets">
                  <div> 
                    <p> <FontAwesomeIcon icon={faArrowDownLong} style={{ color: 'green' }} /> </p>
                    <p> Received NGN 30,000 in wallet for intern - allowance for March 2023 from OACO Professional services </p>
                  </div>
                  <div className="middle">
                    <p> 12/06/2020 </p>
                    <span> 5:45 am </span>
                  </div>
                  <div>
                    <p> ₦1,000,000 </p>
                    <p> Successful </p>
                  </div>
                </div>
                
              </div>
            </div>

            <div className="rightside">
              <button type="button">
                <p> Redeem points <span> <FontAwesomeIcon icon={faArrowRight} /> </span> </p> 
              </button>
            </div>

          </div>

        </div>
      </section>

    </div>
  );

};


