import { Footer } from "components/footer/footer.component";
import { Header } from "components/header/header.component";
import { SignupFormContextProvider } from "context/signup-form.context";
import { AboutPage } from "pages/about/about.page";
import { ContactPage } from "pages/contact/contact.page";
import { Dashboard } from "pages/dashboard/dashboard.component";
import { HomePage } from "pages/home/home.page";
import { PricePage } from "pages/price/price.page";
import { SigninPage } from "pages/signin/siginin.page";
import { SignupPage } from "pages/signup/signup.component";
import { VerifyPage } from "pages/verify/verify.component";
import { Route, Routes } from "react-router-dom"; 
import "./App.scss";
import { useLocation } from 'react-router-dom';
// import Home from "pages/dashboard2/page";
import WalletPage from "pages/dashboard2/wallet/wallet.page";
import TransactionPage from "pages/dashboard2/transactions/transaction.page";
import ServicesPage from "pages/dashboard2/services/services.page";
import PromotionsPage from "pages/dashboard2/promotions/promotions.page";
import SettingsPage from "pages/dashboard2/settings/settings.page";
import ProfilePage from "pages/dashboard2/profile/profile.page";
import { DashHeader } from "components/dashboard/header/header";

function App() {


const location = useLocation();
const pathname = location.pathname;

// let page = pathname.replace('/dashboard/','') || 'Overview';  

// console.log(pathname);

   if (pathname === '/dashboard') {
      return (
        <div className="app-container">
          <DashHeader />
          <Routes>
            <Route path="/dashboard" exact element={<Dashboard title="PaySprint | Dashboard" />} />
          </Routes>
        </div>
      )
    } else if (pathname === '/dashboard/wallet') {
      return (
        <div className="app-container">
          <Routes>
            <Route path="/dashboard/wallet" exact element={<WalletPage title="PaySprint | Wallet" />} />
          </Routes>
        </div>
      )
    } else if (pathname === '/dashboard/transactions') {
      return (
        <div className="app-container">
          <Routes>
              <Route path="/dashboard/transactions" exact element={<TransactionPage title="PaySprint | Transactions" />} />
          </Routes>
        </div>
      )
    } else if (pathname === '/dashboard/services') {
      return (
        <div className="app-container">
          <Routes>
            <Route path="/dashboard/services" exact element={<ServicesPage title="PaySprint | Services" />} />
          </Routes>
        </div>
      )
    } else if (pathname === '/dashboard/promotions') {
      return (
        <div className="app-container">
          <Routes>
              <Route path="/dashboard/promotions" exact element={<PromotionsPage title="PaySprint | Promotions" />} />
          </Routes>
        </div>
      )
    }  else if (pathname === '/dashboard/settings') {
      return (
        <div className="app-container">
            <Routes>
                <Route path="/dashboard/settings" exact element={<SettingsPage title="PaySprint | Settings" />}
  />
            </Routes>
        </div>
      )
    } else if (pathname === '/dashboard/profile') {
      return (
        <div className="app-container">
            <Routes>
                <Route path="/dashboard/profile" exact element={<ProfilePage title="PaySprint | Profile" />}
  />
            </Routes>
        </div>
      )
    } else {

      return (
        <div className="app-container"> 
          <Header />
            <Routes>
              <Route path="/" exact element={<HomePage title="PaySprint | Homepage" />}/>
              <Route path="/about" exact element={<AboutPage title="PaySprint | About Us" />} />
              <Route path="/contact" exact element={<ContactPage title="PaySprint | Contact Us" />} />
              <Route path="/signup" exact element={ <SignupFormContextProvider> <SignupPage title="PaySprint | Sign Up" /> </SignupFormContextProvider>} />
              <Route path="/signin" exact element={<SigninPage title="PaySprint | Sign In" />} />
              <Route path="/pricing" exact element={<PricePage title="PaySprint | Pricing" />} />
              <Route path="/verify" exact element={<VerifyPage title="PaySprint | Verify OTP" />} />
            </Routes>
          <Footer />
        </div>
      ); 
    }



}

export default App;
