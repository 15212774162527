/* eslint-disable jsx-a11y/anchor-is-valid */
import paysprintLogo from "assets/svg/paysprint_logo.svg";
import hamburgerIcon from "assets/svg/hamburger.svg";
import "./header.styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faXmark, } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { getConditionalClassName } from "utils/utils";
import { Link, NavLink } from "react-router-dom";
import notification from 'assets/svg/notify.svg';
import dropdown from 'assets/svg/dropdown.svg';
import theswitch from 'assets/svg/switch.svg';
import user from 'assets/images/user.png';
import { GetToDisp, capitalWord } from "components/usefulJsScripts/routeLocation";
import { SideBar } from "../sidebar/sidebar";



export const DashHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
//   const [isAccountMenuOpen, setIsAcctMenuOpen] = useState(false);

  const toggleIsSubmenuOpen = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

//   const toggleAccountMenu = () => {
//     setIsAcctMenuOpen(!isAccountMenuOpen);
//   };


  return (
    <>
      <nav className="mobile-nav">
        <img src={paysprintLogo} alt="" />
        <div className="buttons">
          <Link to="/"> { capitalWord(GetToDisp()) } </Link>
          <button onClick={() => setIsMenuOpen(true)}>
            <img src={hamburgerIcon} alt="" />
          </button>
        </div>
        <div className={getConditionalClassName(isMenuOpen, "menu", "active")}>
           <button onClick={() => setIsMenuOpen(false)}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
          
          <SideBar />
        </div>
      </nav>
      
      <nav className="desktop-nav" style={{ background: '#fff' }}>
        <header className="main-DashHeader">

            <div className="left">
                <a href="/">
                  <img src={paysprintLogo} alt="PaySprint Icon" />
                </a>
            </div>

            <div className="right">
                <h3> { capitalWord(GetToDisp()) } </h3>
                <div className="split">
                    <img src={notification} alt="notificationIcon" style={{ padding: '1rem', background: '#f5f5f5', borderRadius: '1rem', cursor: 'pointer' }} />
                    <div className="switch">
                        <img src={theswitch} alt="switchICon" />
                        <img src={dropdown} alt="dropdownIcon" /> 
                    </div>
                    <div className="acct">
                        <img src={user} alt="" />
                        <div style={{ display: 'flex', gap: '0.5rem' }}>
                            <p> Adenuga Adebambo</p>
                            <img src={dropdown} alt="dropdownIcon" />
                        </div>
                    </div>
                </div>
            </div>

        </header>
      </nav>
      
    </>

  );

};


