/* eslint-disable jsx-a11y/anchor-is-valid */
import "./sidebar.scss";
import { NavLink, useLocation } from "react-router-dom";
import grid from 'assets/svg/overviewGrid.svg';
import wallet from 'assets/icons/dashboard/wallet.png';
import transact from 'assets/icons/dashboard/transact.png';
import service from 'assets/icons/dashboard/services.png';
import promotion from 'assets/icons/dashboard/promotions.png';
import settings from 'assets/icons/dashboard/settings.png';
import logout from 'assets/icons/dashboard/logout.png';




export const SideBar = () => {

const location = useLocation();
const pathname = location.pathname;

  console.log(pathname);


  return (
    <>
      <div className="side">

        <div className="theFirstHalf">
            {pathname === '/dashboard' ? (
                <NavLink to="/dashboard" style={{ width: '100%' }}>
                <div className="real">
                    <img src={grid} alt="gridIcon" />
                    <p> Overview </p>
                </div>
                </NavLink>
            ) : (
                <NavLink to="/dashboard" style={{ width: '100%' }}>
                <div className="faded">
                    <img src={grid} alt="gridIcon" />
                    <p> Overview </p>
                </div>
                </NavLink>
                )}

            {pathname === '/wallet' ? (
                <NavLink to="/wallet" style={{ width: '100%' }}>
                <div className="real">
                    <img src={wallet} alt="gridIcon" />
                    <p> Wallet </p>
                </div>
                </NavLink>
            ) : (
                <NavLink to="/wallet" style={{ width: '100%' }}>
                    <div className="faded">
                    <img src={wallet} alt="gridIcon" />
                    <p> Wallet </p>
                    </div>
                </NavLink>
                )}

            {pathname === '/transactions' ? (
                <NavLink to="/transactions" style={{ width: '100%' }}>
                <div className="real">
                    <img src={transact} alt="gridIcon" />
                    <p> Transactions </p>
                </div>
                </NavLink>
            ) : (
                <NavLink to="/transactions" style={{ width: '100%' }}>
                    <div className="faded">
                    <img src={transact} alt="gridIcon" />
                    <p> Transactions </p>
                    </div>
                </NavLink>
                )}

            {pathname === '/services' ? (
                <NavLink to="/services" style={{ width: '100%' }}>
                <div className="real">
                    <img src={service} alt="gridIcon" />
                    <p> Services </p>
                </div>
                </NavLink>
            ) : (
                <NavLink to="/services" style={{ width: '100%' }}>
                    <div className="faded">
                    <img src={service} alt="gridIcon" />
                    <p> Services </p>
                    </div>
                </NavLink>
                )}

            {pathname === '/promotion' ? (
                <NavLink to="/promotion" style={{ width: '100%' }}>
                <div className="real">
                    <img src={promotion} alt="gridIcon" />
                    <p> Promotion </p>
                </div>
                </NavLink>
            ) : (
                <NavLink to="/promotion" style={{ width: '100%' }}>
                    <div className="faded">
                    <img src={promotion} alt="gridIcon" />
                    <p> Promotion </p>
                    </div>
                </NavLink>
                )}
        </div>

        <div className="theSecondHalf">
            {pathname === '/settings' ? (
                <NavLink to="/settings" style={{ width: '100%' }}>
                    <div className="real">
                    <img src={settings} alt="gridIcon" />
                    <p> Settings </p>
                    </div>
                </NavLink>
                ) : (
                    <NavLink to="/settings" style={{ width: '100%' }}>
                    <div className="faded">
                        <img src={settings} alt="gridIcon" />
                        <p> Settings </p>
                    </div>
                    </NavLink>
                    )}

            {pathname === '/logout' ? (
            <NavLink to="/logout" style={{ width: '100%' }}>
                <div className="real">
                <img src={logout} alt="gridIcon" />
                <p style={{ color: 'red' }}> Sign Out </p>
                </div>
            </NavLink>
            ) : (
            <NavLink to="/logout" style={{ width: '100%' }}>
                <div className="faded">
                <img src={logout} alt="gridIcon" />
                <p style={{ color: 'red' }}> Sign Out </p>
                </div>
            </NavLink>
            )}
        </div>

      </div>
      
    </>

  );

};


